<template>
  <main>
    <div class="row mt-5 m-0">
      <div class="col-xl-10 m-auto">
        <div class="row mb-4">
          <h2 class="col-xl-9">Parties</h2>
          <div class="col-xl-3">
            <b-form-select class="form-select mb-3" v-model="filtre_name" >
              <b-form-select-option  value="">Clients</b-form-select-option>
              <b-form-select-option v-for="c in clients" :key="c.fullname" :value="c.fullname">{{c.fullname}}</b-form-select-option>
            </b-form-select>
<!--            <b-input-group >-->
<!--              <template #prepend>-->
<!--                <b-input-group-text><i class="fa fa-search"></i></b-input-group-text>-->
<!--              </template>-->
<!--              <b-form-input type="search" v-model="filtre_name" ></b-form-input>-->
<!--            </b-input-group>-->
          </div>
        </div>
        <div class="mb-4" style="border-radius:12px;border: solid 3px #32394e">
          <table class="text-center">
            <thead>
            <tr>
              <th scope="col" >Id</th>
              <th scope="col" >Client</th>
              <th scope="col">Appareils</th>
              <th scope="col">Price</th>
              <th scope="col">Date de Début</th>
              <th scope="col">Date de Fin</th>
              <th scope="col"> Payé ? </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in parties" :key="data.id">
              <td data-label="Id">{{data.id}}</td>
              <td data-label="Client">{{data.client.fullname}}</td>
              <td data-label="Appareils">{{data.devices_count}}</td>
              <td data-label="Price">{{data.price_per_device}}</td>
              <td data-label="Date de Début"> {{data.begin_at}}</td>
              <td data-label="Date de Fin"> {{data.finish_at?data.finish_at:'-'}}</td>
              <td data-label="Payé ?" >
                <div class="form-check form-switch float-xl-none float-end">
                  <input class="form-check-input ms-auto" v-model="data.is_paid" @change="changePaid(data.id)" type="checkbox" id="flexSwitchCheckDefault" :true-value="1" :false-value="0">
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <infinite-loading @infinite="infiniteHandler" class="text-center">
          <div slot="no-more">Aucune autre donnée trouvée</div>
          <div slot="no-results">Aucune donnée trouvée</div>
        </infinite-loading>

      </div>
    </div>
  </main>
</template>

<script>


export default {
  name: "Parties",
  data(){
    return{
      parties:[],
      clients:[],
      page:1,
      filtre_name:''
    }
  },
  watch:{
    filtre_name: function(){this.filterSearch()}
  },
  created() {
    this.getClients()
  },
  methods:{

    infiniteHandler($state) {
      this.$http.get('parties?page=' +this.page).then(({ data }) => {
        if (data.data.length) {
          this.page += 1;
          this.parties.push(...data.data.map( p => ({ ...p,is_paid:p.paid_at?1:0})));
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    filterSearch() {
      this.$http
          .post('search/parties',{'fullname':this.filtre_name})
          .then(response => {
            console.log(response.data)
            let parties = response.data.data
            this.parties = parties.map( p => ({ ...p,is_paid:p.paid_at?1:0}));
          })
          .catch(error => {
            console.log(error)
          })
    },
    changePaid(id){
      this.$http
          .post('parties/'+id+'/paid')
          .then(() => {
            this.$alertify.success(" success")
          })
          .catch(error => {
            console.log(error)
          })
    },
    getClients(){
      this.$http
          .get('clients')
          .then(response => {
            this.clients = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}

</script>

<style scoped  src="@/assets/table.css"></style>